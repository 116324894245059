
import {interval as observableInterval, fromEvent as observableFromEvent,  Observable, Subject, BehaviorSubject,ReplaySubject ,  timer, from } from 'rxjs';

import {map, combineLatest, takeUntil,  debounceTime, throttleTime ,  bufferTime,count,take,takeWhile, mergeMap, tap, toArray } from 'rxjs/operators';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  ChangeDetectionStrategy,
  ViewContainerRef,
  Input,
  NgZone,
  ViewChild,
  OnDestroy
} from '@angular/core';

import { AlertService } from '../service/alert.service';


import { UsersService } from '../service/users.service';
import { ChatService } from '../service/chatservice';
import { Room } from '../model/room.model';
import { User } from '../model/user';
import { RoomsService } from '../service/rooms.service';
import { Message } from '../model/message.model';
import { MessagesService } from '../service/messages.service';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import {  ToastrService } from 'ngx-toastr';
import { RoomFactory } from '../model/RoomFactory';
import { UserFactory } from '../model/userFactory';
import { MessageType } from '../model/messageType';
import { Howl, Howler } from 'howler';
import { AuthenticationService } from '../service/authentication.service';
//import {  defineComponent } from '@angular/core/src/render3';
import { LocalizationService } from '../service/localization.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { MentorSpinnerService } from '../service/mentorspinner.service';

import { CustomizationService } from '../service/customization.service';
import { AppConfig } from '../AppConfig';
import {
  messageFactory
} from '../model/messageFactory';

import { ChatPersonalization } from '../model/chatpersonalization.model';
import { DatePipe } from '@angular/common'
import { MessageAttachment } from '../model/MessageAttachment.model';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TicketService } from '../service/ticket.service';
import { ImageUploadModalComponent } from '../image-upload-modal/image-upload-modal.component';
import { uuid } from '../home/util/uuid';

@Component({
  selector: 'app-client-window',
  templateUrl: './client-window.component.html',
  styleUrls: ['./client-window.component.css'],

})
export class ClientWindowComponent implements OnInit,OnDestroy {


  @Input() data: any;
  messages: Observable<any>;
  currentRoom: Room;
  draftMessage: Message;
  currentUser: User;
  isMessagePrivate: boolean = false;
  nickName: string = '';
  isHidden: boolean;
  paramWidget: boolean = false;
  typingMessage: string = "";
  public typingUsrs: Array<User> = [];
  private subjectKeyDown: Subject<any> = new BehaviorSubject<any>('');
  public keyUp = new Subject<string>();
  messagesToPrint: Array<Message> = [];
  showChatColor: boolean = false;
  hideEndChatButton: boolean = true;
  public logoFullPath: string = '';
  public colorFileFullPath: string = '';
  public chatColor: string = '#0061AA';
  //localization - s
  private pageName = "ClientWindowComponent";
  public pageTranslation: any = "";
  
  //localization - e
  localLang: any = "en";
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  intervalMs:number = 1000;
  //firstInterval:number=60;
  //secondInterval:number=4*this.firstInterval;
  sumInterval:number=0;
  sumInterval1:number=0;
  sumInterval2:number=0;
  intervalMap = new Map<number, string>();
  private intervalStop = false;
  private intervalStop1 = false;
  private intervalStop2 = false;
  public twoCombined = new Observable<boolean>();
  public personalizationRetrived = new BehaviorSubject<boolean>(false);
  public pageTranslated = new BehaviorSubject<boolean>(false);
  @ViewChild('textInput',{static:false}) textInput: ElementRef;
  messageAttachmentSubject: BehaviorSubject<MessageAttachment[]> = new BehaviorSubject<MessageAttachment[]>([]);
  messageAttachments:Observable<MessageAttachment[]>=this.messageAttachmentSubject.asObservable();
  messageAttachmentsArray:MessageAttachment[]=[];
  hasMessageAttachments:boolean=false;

  constructor(
    public mspinnerService: MentorSpinnerService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private localizationService: LocalizationService,
    private customizationService: CustomizationService,
    public messagesService: MessagesService,
    public roomsService: RoomsService,
    public userService: UsersService,
    public chatService: ChatService,
    public toastr: ToastrService,
    public vcr: ViewContainerRef,
    public el: ElementRef,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private config: AppConfig,
    public ticketService: TicketService,
		private dialog: MatDialog,
    public datepipe: DatePipe){
    
    this.intervalMap.set(25, "25secondsWaiting");   
    this.intervalMap.set(60,  "1minuteWaiting"); 
    this.intervalMap.set(180, "2minuteWaiting");

    // this.colorFileFullPath = this.config.logoURL + this.userService.getClientIdParam().toString() + '.txt';
    //this.chatColor = this.customizationService.getPersonalizedColor(this.colorFileFullPath);
    this.logoFullPath = customizationService.getNoCacheImage(this.config.logoURL + this.userService.getClientIdParam().toString() + '.png');


    
this.userService.cpSubject.pipe(takeUntil(this.onDestroy$)).subscribe(
  (data: ChatPersonalization) => {
    
    this.chatColor= data.colorCode;
    this.personalizationRetrived.next(true);
    //
  });

  
  this.twoCombined= this.pageTranslated.pipe(takeUntil(this.onDestroy$),combineLatest(
    this.personalizationRetrived, (pageT: boolean, personalizationR: boolean) => {

      return pageT&&personalizationR;
    }),);

    // this.userService.GetChatPersonalization(Number(this.userService.getClientIdParam()),this.userService.getClientTypeParam())
    // .takeUntil(this.onDestroy$).subscribe((personalization:any)=>
    // {
    //   this.chatColor=personalization.colorCode;
    //   if(personalization.logo && personalization.logo!='')
    //   {
    //     this.logoFullPath=this.config.logoURL +personalization.logo;
    //   }else
    //   {
    //     this.logoFullPath= 'assets/images/logo.png';
    //   }
     
    // });
    this.SetLocalization();

    // this.toastr.setRootViewContainerRef(vcr);

    const keyUpEvent = observableFromEvent(el.nativeElement, 'keyup');
    const result = keyUpEvent.pipe(takeUntil(this.onDestroy$)).pipe(throttleTime(4000));
    result.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.chatService.StartTyping(this.currentRoom.id, this.isMessagePrivate);
    });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  ngOnInit(): void {

     this.userService.currentUser.pipe(
     takeUntil(this.onDestroy$)).subscribe(
        (user: User) => {
          this.currentUser = user;
        });
    
    this.messages = this.roomsService.currentRoomMessagesForClient.pipe(takeUntil(this.onDestroy$),map(messages=>{
          let mesgs= messages.filter(mess=>!(mess.msgType==MessageType.System && mess.author.id==this.currentUser.id))
          return mesgs;
         }
    ),);

    this.draftMessage = new Message();
    this.draftMessage.text = '';

    this.messagesService.newMessageNotification.pipe(takeUntil(this.onDestroy$)).subscribe((rez) => {
      this.ngZone.run(() => {
        var sound = new Howl({
          src: ['assets/audio/definite.mp3']
        });

        sound.play();
      });
    });

    this.roomsService.currentRoom.pipe(takeUntil(this.onDestroy$)).subscribe(
      (room: Room) => {
        this.currentRoom = room;
        this.currentRoom.name = this.currentRoom.name.substr(0, this.currentRoom.name.indexOf("/"));
      });

    this.userService.currentUser.pipe(
    takeUntil(this.onDestroy$)).subscribe(
        (user: User) => {
          if (user != null) {
            this.currentUser = user;
            if (user.isMentor)
              this.isHidden = true;
            else
              this.isHidden = false;

          }
          else {
            this.currentUser = UserFactory.createEmptyUser();
          }
        });

    this.messages.pipe(
    takeUntil(this.onDestroy$)).subscribe(
        (messages: Array<Message>) => {
          this.ngZone.run(() => {
            setTimeout(() => {
              this.scrollToBottom();
              this.showChatColor = true;
              this.hideEndChatButton = false;
            });
          });


          this.messagesToPrint = new Array<Message>();
          messages.filter(m => m.msgType === MessageType.Default).forEach(item => {
            this.messagesToPrint.push(item);
          })
        });

    let intervalObservable = observableInterval(25000).pipe(takeWhile(() => !this.intervalStop))
        .subscribe(() => {
          
          console.log(25);
            //var getValue=this.messages.getValue();
            this.messages.pipe(take(1)).subscribe( (messages: Array<Message>) => {
               
              if(messages.length>0)
                {
                  let msgFiltered = messages.filter(mess=>(mess.author.id!=this.currentUser.id 
                    && (mess.orgMsgType==MessageType.Default || mess.orgMsgType==MessageType.System)));
                  if(msgFiltered.length===0)
                  {
                    this.sendPleaseWaitMessage(this.intervalMap.get(25));
                    this.intervalStop=true;
                  }else
                  {
                    this.intervalStop=true;
                  }
                }
            });
         
        });

        let intervalObservable1 = observableInterval(60000).pipe(takeWhile(() => !this.intervalStop1))
        .subscribe(() => {
          
          console.log(60);
            //var getValue=this.messages.getValue();
            
            this.messages.pipe(take(1)).subscribe( (messages: Array<Message>) => {

              if(messages.length>0)
                {
                  let msgFiltered = messages.filter(mess=>(mess.author.id!=this.currentUser.id 
                    && (mess.orgMsgType==MessageType.Default || mess.orgMsgType==MessageType.System)));
                  if(msgFiltered.length===0)
                  {
                    
                    this.sendPleaseWaitMessage(this.intervalMap.get(60));
                    this.intervalStop1=true;
                  }else
                  {
                    this.intervalStop1=true;
                  }
                }
            
            });
         
        });


        let intervalObservable2 = observableInterval(180000).pipe(takeWhile(() => !this.intervalStop2))
        .subscribe(() => {
          
          console.log(180);
            //var getValue=this.messages.getValue();
            this.messages.pipe(take(1)).subscribe( (messages: Array<Message>) => {
            
              if(messages.length>0)
                {
                  let msgFiltered = messages.filter(mess=>(mess.author.id!=this.currentUser.id 
                    && (mess.orgMsgType==MessageType.Default || mess.orgMsgType==MessageType.System)));
                  if(msgFiltered.length===0)
                  {
                    
                    this.sendPleaseWaitMessage(this.intervalMap.get(180));
                    this.intervalStop2=true;
                  }else
                  {
                    this.intervalStop2=true;
                  }
                }  
            
            });
         
        });


    this.userService.emailTransciptErr.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      window.alert("Email sending failed. Please make sure that the e-mail provided is valid.");
    });

    this.userService.emailTranscriptSuccess.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      window.alert("Email has been sent. Please check your inbox for the email transcript.");
    });

    this.messagesService.typingNotification.pipe(takeUntil(this.onDestroy$)).subscribe((usr) => {
      if (usr === null) return;
      if (this.typingUsrs.findIndex(s => s.name == usr.name) === -1) {

        this.typingUsrs.push(usr);
        clearTimeout(timer);
        var timer = setTimeout(() => {
          var index = this.typingUsrs.findIndex(s => s.name == usr.name);
          if (index !== -1) {
            this.typingUsrs.splice(index, 1);
          }
        }, 6000);

      }
    });



    this.messagesService.typingNotificationCancel.pipe(
    takeUntil(this.onDestroy$)).subscribe((usr) => {
        if (usr === null) return;
        var index = this.typingUsrs.findIndex(s => s.name == usr.name);
        if (index !== -1) {
          this.typingUsrs.splice(index, 1);
        }
      });   
  }

  addElementToObservableArray(item) {
    this.messageAttachments.pipe(take(1)).subscribe(val => {
      console.log(val)
      const newArr = [...val, item];
      this.messageAttachmentsArray=newArr;

      if(this.messageAttachmentsArray.length>0)
        this.hasMessageAttachments=true;
      else
        this.hasMessageAttachments=false;

      //this.changeDetector.markForCheck();
      this.messageAttachmentSubject.next(newArr);
    })
  }

  removeAllArray() {
    this.messageAttachments.pipe(take(1)).subscribe(val => {
      //const arr = this.messageAttachmentSubject.getValue()
      const newArr = [];
      this.messageAttachmentsArray=newArr;
      if(this.messageAttachmentsArray.length>0)
        this.hasMessageAttachments=true;
      else
        this.hasMessageAttachments=false;


      this.messageAttachmentSubject.next(newArr)
    })
  }

  removeElementToObservableArray(item) {
    this.messageAttachments.pipe(take(1)).subscribe(val => {
      const newArr = this.messageAttachmentSubject.getValue()
      newArr.splice(item, 1);

      this.messageAttachmentsArray=newArr;
      if(this.messageAttachmentsArray.length>0)
        this.hasMessageAttachments=true;
      else
        this.hasMessageAttachments=false;

      this.messageAttachmentSubject.next(newArr)
    })
  }

  uploadImage(event: any)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ImageUploadModalComponent, {
      height: 'auto',
      width: '600px',
      data: {}
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      val => {
        if (val !== null && val != undefined && val) {
          if (val.length === 0) {
            return;
          }
          let fileToUpload = <File>val;
          const formData = new FormData();
          var fileName=fileToUpload.name;

          formData.append('file', fileToUpload, uuid()+'.png');
          formData.append('roomId',this.currentRoom.id);
          formData.append('userId',this.currentUser.id);
          formData.append('fileName',fileName);
          //formData.append('roomId',this.currentRoom.id);
          
          this.mspinnerService.ShowSpinner();
          this.ticketService.uploadImage(formData)
          .subscribe(result => {
            this.mspinnerService.HideSpinner();
            if (!result.HasError) {
              var attachment=new MessageAttachment(
              {
                ImageId: result.ImageUrl,
                ImageUri: result.ImageUrl,
                ThumbnailUri: result.ThumbUrl,
                ContainerName:result.ContainerName,
                FileName:fileName,
                MessageId:'',
                UserId:this.currentUser.id
              });
              this.messageAttachmentsArray.push(attachment);
              this.sendMessage(this.textInput.nativeElement.value);
            }
          
          },
          error => {
            this.mspinnerService.HideSpinner();
            this.userService.clientTostrErrorNotification.next("Error while uploading the image to chat. Please refresh the page and try again.");
          });
          
        } else if (val !== null && val != undefined && val == false) {
          
        } else {
          
        }
      }
    );
    dialogRef.disableClose = false;
  }

  sendPleaseWaitMessage(timePassed:string){
    let msg = messageFactory.createClientInitMessage(timePassed);
    msg.room = this.currentRoom;
    msg.author = JSON.parse(localStorage.getItem('currAuthor'));
    this.chatService.sendPleaseWaitMessage(msg);
  }

  getBackground(image) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  onEnter(event: any): void {
    this.sendMessage(this.textInput.nativeElement.value);
    this.textInput.nativeElement.value = '';
    event.preventDefault();
  }

  onCloseChat(event: any): void {
    this.chatService.closeRoom(this.currentRoom);
    event.preventDefault();
  }

  sendMessage(value: string): void {
    if (value.match(/^\s*$/) && this.messageAttachmentsArray.length==0) {
      return;
    }
    if (value == null && this.messageAttachmentsArray.length==0) return;
    if (value === '' && this.messageAttachmentsArray.length==0) return;
    if (value.length <= 0 && this.messageAttachmentsArray.length==0) return;
    value = value.replace("<", "< ");

    this.draftMessage = new Message();
    this.draftMessage.text = value;

    const m: Message = this.draftMessage;

    m.author = JSON.parse(localStorage.getItem('currAuthor'));
    if (this.nickName !== '')
      m.author.userName = this.nickName;

    m.msgType = MessageType.Default;

    m.room = this.currentRoom;
    m.room.roomStatus = 4;
    if (m.author.isMentor)
      m.room.roomStatus = 3;

    m.isRead = true;
    m.isPrivate = this.isMessagePrivate;
    m.sentAt = new Date();

    

    m.messageAttachments=this.messageAttachmentsArray;
    if(m.messageAttachments.length>0)
    {
      this.mspinnerService.ShowSpinner();
      from(m.messageAttachments).pipe(
        mergeMap(module => this.ticketService.downloadFile(module.ContainerName,module.ThumbnailUri).pipe(
            tap(apiResponse => {
              //let objectURL = URL.createObjectURL(apiResponse);       
              //module.ThumbnailUriUrl = apiResponse;
              let objectURL = URL.createObjectURL(apiResponse);       
              module.ThumbnailUriUrl = objectURL;
            })
        )),
        toArray()
      ).pipe(takeUntil(this.onDestroy$)).subscribe(allResponses => {
          this.mspinnerService.HideSpinner();
          console.log(allResponses);
          
          
          this.chatService.sendMessage(m);
          this.messageAttachmentsArray=[];
          this.draftMessage = new Message();
          this.draftMessage.text = "";
          setTimeout(() => {
            this.scrollToBottom();
          });
      },
      error => {
        this.mspinnerService.HideSpinner();
        this.userService.clientTostrErrorNotification.next("Error while trying to display the image. Please refresh the page and try again.");
      });

    }else
    {
      this.chatService.sendMessage(m);
      this.draftMessage = new Message();
      this.draftMessage.text = "";
      this.messageAttachmentsArray=[];
      setTimeout(() => {
        this.scrollToBottom();
      });
    }
  }

  sendEmailTranscript() {
    this.chatService.SendEmailTranscript(this.currentUser, this.currentRoom);
  }

  copyToClipboard() {
    let textToCopy = '';
    this.messagesToPrint.forEach((item) => {

      if (item.author != null && item.author != undefined && item.text != null && item.text != undefined && item.msgType != 1){
        var userType = " (user) ";
        if(item.author.isMentor)
          userType = " (mentor) ";

          textToCopy += item.author.name + userType + this.datepipe.transform(item.sentAt, 'HH:mm:ss a | MMM dd') + ":" + '\n' + item.text + '\n\n';
      }     
    })
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = textToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  printTranscript(event: any) {
    let printContents, popupWin;
    printContents = document.getElementById('print-content').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Chat Transcript</title>
        </head>
        <body onload="window.print();window.close();">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  uploadAttachment(event: any) {
    
  }

  SetMsgNickname(nickname: string) {
    this.nickName = nickname;
  }

  scrollToBottom(): void {
    const scrollPane: any = this.el
      .nativeElement.querySelector('.msg-container-base');
      if(scrollPane)
        scrollPane.scrollTop = scrollPane.scrollHeight;
  }

  SetMsgType(): void {
    this.isMessagePrivate = !this.isMessagePrivate;
  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);
    this.localLang = locale;

    this.localizationService.GetLocalizationData().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.pageTranslation = data[0][this.pageName][locale];
      this.pageTranslated.next(this.pageTranslation);
    },
      error => { console.log(error) }, () => { });
  }

  trackByMessageID(msg: any): string {
    return msg.id;
  }

}
