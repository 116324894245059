<div *ngIf="twoCombined | async">
  <div id="print-content" style="display:none;">

    <div *ngFor="let message of messagesToPrint">

      <div *ngIf="!message.author.isMentor">
        <p>
          <span>{{message.author.name}} (user) | {{message.sentAt | date: 'HH:mm:ss a'}} | {{message.sentAt | date: 'MMM dd'}} : </span>
          <br>
          <span style="color:#65858c;">{{message.text}}</span>
          <br>
        </p>
      </div>

      <div *ngIf="message.author.isMentor" style="text-align:right;">
        <p>
          <span>{{message.author.name}} (mentor) | {{message.sentAt | date: 'HH:mm:ss a'}} | {{message.sentAt | date: 'MMM dd'}} : </span>
          <br>
          <span style="color:#65858c">{{message.text}}</span>
          <br>
        </p>
      </div>
         
    </div>
 
  </div>

  <div class="chat-window-container {{paramWidget}}">
    <div class="chat-main-window client-main-window">
      <div class="panel-container">
        <div class="panel panel-default">


          <div class="regHeader"
            [style.background-color]="chatColor">

            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3">
              <div class="pLogo"><img class="partner-logo" src="{{logoFullPath}}"
                  onerror="this.onerror=null; this.src='assets/images/logo.png'">
                </div> 
            </div>

            <div class="col-md-9 col-lg-9 col-sm-9 col-xs-9 pName">
              <div class="chat-room-name-header">{{currentRoom.name}}</div>
            </div>

            <span class="input-group-btn close-btn" *ngIf="currentRoom.name !== 'Lobby'">
              <button [ngClass]="{ 'hidden': hideEndChatButton }" data-tooltip="End chat"  class="btn-close"
                      (click)="onCloseChat($event)">
                      <img src="assets/images/end-chat.png" title="End chat" alt="End chat" /> 
              </button>
            </span>
          </div>


          <div id="content">
            <div class="area"></div>
            <div class="panel-body msg-container-base " style="width: 100%; overflow: scroll; overflow-x: hidden">
              <div class="disclaimer-content" >
                <p class="disclaimer-header" >{{pageTranslation.DisclaimerHeader}}</p>
                <p  class="disclaimer-text">{{pageTranslation.DisclaimerBody}}</p><!--bindings={
                "ng-reflect-ng-if": "false"}-->
              </div>
              <chat-message *ngFor="let message of messages | async;" [message]="message"></chat-message>              
            </div>
            <div class="msg-is-typing-outer">
              <div class="msg-is-typing">
                <p>{{typingUsrs | typing: localLang}}</p>
              </div>
            </div>
          </div>

          <div class="chat-footer">
            <!-- <div class="attachmentsWrapper position-relative" *ngIf="hasMessageAttachments">
              <div class="mb-2">
                <div *ngFor="let attachment of messageAttachments | async">
                  <div class="attachmentHolder">
                    <mat-icon class="" fontIcon="image"></mat-icon>
                    <a [href]="" target="_blank">{{attachment.FileName}}</a>
                    <button mat-icon-button type="button" (click)="removeElementToObservableArray(attachment);" class="cancel">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="panel panel-footer">

              <div class="panel-footer-right">
                <div class="panel-footer">
                  <div class="input-group footer-top">

                    <textarea class="chat-input" #textInput placeholder="{{pageTranslation.TypeYourMessage}}"
                      (keydown.enter)="onEnter($event)" [value]="draftMessage.text"></textarea>

                    <div class="chat-buttons">
                      <span class="input-group-btn">
                        <button class="btn-chat" (click)="onEnter($event)"> <img
                            src="assets/images/send-btn.png" /></button>
                      </span>
                    </div>
                  </div>
                  
                  <div class="input-group footer-bottom">
                    <div class="client-footer-icon">
                      <button (click)="copyToClipboard()">
                        <i class="fa fa-file fa-2x"></i>
                        <span class="tooltiptext">{{pageTranslation.CopyToClipboard}}</span>
                      </button>
                    </div>

                    <div class="client-footer-icon">

                      <button (click)="printTranscript($event)">
                        <i class="fa fa-print fa-2x"></i>
                        <span class="tooltiptext">{{pageTranslation.PrintTranscript}}</span>
                      </button>

                    </div>
                    <div class="client-footer-icon" >

                      <button (click)="uploadImage($event)">
                        <i class="fa fa-upload fa-2x"></i>
                        <span class="tooltiptext">{{pageTranslation.UploadImageButton}}</span>
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>
