
import {map} from 'rxjs/operators';

import {throwError as observableThrowError,  Subject, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../model/user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { UsersInfo } from '../model/usersInfo';
import { UserChatTopic } from '../model/userchattopic.model';
import { Router, ActivatedRoute } from '@angular/router';
import {
  DecryptedUserInfo
} from '../model/decryptedUserInfo.model';
import { AppConfig } from '../AppConfig';
import { ChatPersonalization } from '../model/chatpersonalization.model';

/**
 * UserService manages our current user
 */
@Injectable()
export class UsersService {

  currentUser: Subject<User> = new BehaviorSubject<User>(null);
  clickedUserInfo: Subject<any> = new Subject<any>();
  emailTranscriptSuccess: Subject<any> = new Subject<any>();
  emailTransciptErr: Subject<any> = new Subject<any>();
  isExisting: boolean;
  alreadyEntered: Subject<any> = new Subject<any>();
  registeredUserData: Subject<any> = new Subject<any>();
  clientNotFound: Subject<any> = new Subject<any>();
  clientTostrSuccessNotification: Subject<any> = new Subject<any>();
  clientTostrErrorNotification: Subject<any> = new Subject<any>();
  public clientId: Subject<number> = new BehaviorSubject<number>(null);
  public clientType: Subject<string> = new BehaviorSubject<string>(null);
  public cpSubject: Subject<ChatPersonalization> = new ReplaySubject <ChatPersonalization>();
  public clientIdParam: string; public clientTypeParam: string; public clientLanguageParam: string;
  urlReferer: string;

  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute, private config: AppConfig) {
    let clientId = this.activatedRoute.snapshot.queryParams["clientId"];
    let clientType = this.activatedRoute.snapshot.queryParams["clientType"];
    let clientLanguage = this.activatedRoute.snapshot.queryParams["lang"];

    this.clientId.next(clientId);
    this.clientType.next(clientType);
    this.clientIdParam = clientId;
    this.clientTypeParam = clientType;
    this.clientLanguageParam = clientLanguage;
    this.urlReferer="";
  }

  public UserNotFound(errMsg) {
    this.clientNotFound.next(errMsg);
  }

  public setCurrentUser(newUser: User): void {
    localStorage.setItem('currentUser', JSON.stringify(newUser));
    this.currentUser.next(newUser);

  }

public  checkMentorRole(type:string):Boolean
{
  if(+type==0) // soba tip lobby
    return true;

  let currentUser = JSON.parse(localStorage.getItem('currAuthor'));

  if(type.toUpperCase() == currentUser.role.toUpperCase())
    return true;

  if(type.toUpperCase() == 'TELCO' && currentUser.role.toUpperCase() == 'TELCO ADMIN')
    return true;

  if(type.toUpperCase() == 'MSP' && currentUser.role.toUpperCase() == 'MSP ADMIN')
    return true;

  if(type.toUpperCase() == 'EDU' && currentUser.role.toUpperCase() == 'TELCO')
  return true;

  if(type.toUpperCase() == 'EDU' && currentUser.role.toUpperCase() == 'TELCO ADMIN')
  return true;
  
  if(currentUser.role.toUpperCase() == 'ADMIN')
    return true;

  return false;
}



  sendUserInfo(userInfo: UsersInfo) {
    this.clickedUserInfo.next(userInfo);
  }

  create(user: User) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('email', user.userName);
    urlSearchParams.append('password', user.password);
    urlSearchParams.append('firstName', user.lastName);
    urlSearchParams.append('lastName', user.firstName);

    let body = urlSearchParams.toString();
    return this.http.post<any>('/api/account/register', body, { headers: headers });

  }

  RegisterToChatTelco(user: User) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 
    'url-referrer': this.urlReferer });

    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('password', "123123");
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('issue', user.issue);
    urlSearchParams.append('subissue', user.subissue);
    urlSearchParams.append('message', user.message);
    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('ClientId', this.getClientIdParam());
    urlSearchParams.append('clientType', this.getClientTypeParam());

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());

    //todo: refactor via factory method
    const uct: UserChatTopic = new UserChatTopic();
    uct.issue = user.issue
    uct.subissue = user.subissue;
    localStorage.setItem("userChatTopic", JSON.stringify(uct));

    let exists: boolean;
    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterToChatTelco?clientId=' + this.getClientIdParam() + '&clientType=' + this.getClientTypeParam(), body, { headers: headers });
    return rez;
  }

  RegisterToChatMsp(user: User) {
    let lang = 'en';
    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      lang = 'en';  
    else
      lang = this.getClientLanguageParam();

    //todo: refactor via factory method
    const uct: UserChatTopic = new UserChatTopic();
    uct.issue = user.issue
    uct.subissue = user.subissue;
    localStorage.setItem("userChatTopic", JSON.stringify(uct));


    let headers: HttpHeaders = new HttpHeaders({ 'url-referrer': this.urlReferer,'failedLoginAttempts':this.getFailedLogInAttempts()+'' });
   

    const body = { Email:  user.email, 
      ClientId: this.getClientIdParam(), 
      FirstName: user.firstName,
      LastName: user.lastName,
      issue: user.issue,
      subissue: user.subissue,
      message: user.message,
      b1number:user.b1number,
      voucher:user.voucher,      
      clientType: this.getClientTypeParam(), 
      language: this.getClientLanguageParam() };

    return this.http.post<any>(this.config.origin + '/api/account/RegisterToChatMsp', body, { headers: headers });

  }

  RegisterMspWithVerification(user: any) {
    let lang = 'en';
    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      lang = 'en';  
    else
      lang = this.getClientLanguageParam();


    //todo: refactor via factory method
    const uct: UserChatTopic = new UserChatTopic();
    uct.issue = user.issue
    uct.subissue = user.subissue;
    localStorage.setItem("userChatTopic", JSON.stringify(uct));

    



    let headers: HttpHeaders = new HttpHeaders({ 'url-referrer': this.urlReferer,'failedLoginAttempts':this.getFailedLogInAttempts()+'' });
   

    const body = { Email:  user.email, 
      ClientId: this.getClientIdParam(), 
      FirstName: user.firstName,
      LastName: user.lastName,
      issue: user.issue,
      subissue: user.subissue,
      message: user.message,
      EmailCode:user.verificationCode,
      b1number:user.b1number,
      voucher:user.voucher,      
      clientType: this.getClientTypeParam(), 
      language: this.getClientLanguageParam() };

    return this.http.post<any>(this.config.origin + '/api/account/RegisterMspWithVerification', body, { headers: headers });

  }

  ResendVerification(user: any) {
    let lang = 'en';
    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      lang = 'en';  
    else
      lang = this.getClientLanguageParam();


    //todo: refactor via factory method

    let headers: HttpHeaders = new HttpHeaders({ });
   

    const body = { 
      Email:  user.email, 
      ClientId: this.getClientIdParam(), 
      FirstName: user.firstName,
      LastName: user.lastName,
      clientType: this.getClientTypeParam(), 
      language: this.getClientLanguageParam() 
    };

    return this.http.post<any>(this.config.origin + '/api/account/ResendVerificationCode', body, { headers: headers });

  }

  RegisterToChatMspAD() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

   
    let lang = 'en';
    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      lang = 'en';  
    else
      lang = this.getClientLanguageParam();

    const body = { Email:  currentUser.email, 
      ClientId: this.getClientIdParam(), 
      FirstName: currentUser.FirstName,
      LastName: currentUser.lastName,
      issue: currentUser.issue,
      subissue: currentUser.subissue,
      message: currentUser.message,
      b1number:currentUser.b1number,
      voucher:currentUser.voucher,      
      clientType: this.getClientTypeParam(), 
      language: lang };
   
    return this.http.post<any>(this.config.origin + '/api/account/RegisterToChatMsp', body).pipe(
       map((res: Response) => res.json()));    
  }

  _errorHandler(error: Response) {
    console.log(error);
    return observableThrowError(error || "Internal server error");
  }



  RegisterToChatGuest(user: User) {
    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('password', "123123");
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('issue', user.issue);
    urlSearchParams.append('subissue', user.subissue);
    urlSearchParams.append('message', user.message);
    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('ClientId', this.getClientIdParam());
    urlSearchParams.append('clientType', this.getClientTypeParam());

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());

    //todo: refactor via factory method
    const uct: UserChatTopic = new UserChatTopic();
    uct.issue = user.issue
    uct.subissue = user.subissue;
    localStorage.setItem("userChatTopic", JSON.stringify(uct));

    let exists: boolean;
    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterToChatGuest?clientId=' + this.getClientIdParam() + '&clientType=' + this.getClientTypeParam(), body, { headers: headers });
    return rez;
  }

  RegisterToChatGuestExternal(userInfo: DecryptedUserInfo) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('email', userInfo.email);
    urlSearchParams.append('password', "123123");
    urlSearchParams.append('firstName', userInfo.firstName);
    urlSearchParams.append('lastName', userInfo.lastName);
    urlSearchParams.append('message', userInfo.message);
    urlSearchParams.append('ClientId', this.getClientIdParam());
    urlSearchParams.append('clientType', this.getClientTypeParam());

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());

    //todo: refactor via factory method

    let exists: boolean;
    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterToChatGuest?clientId=' + this.getClientIdParam() + '&clientType=' + this.getClientTypeParam(), body, { headers: headers });
    return rez;
  }

  CreateFailedLoginTicket(user: User){
    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('password', "123123");
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('issue', user.issue);
    urlSearchParams.append('subissue', user.subissue);
    urlSearchParams.append('message', user.message);
    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('ClientId', this.getClientIdParam());
    urlSearchParams.append('clientType', this.getClientTypeParam());

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());

    //todo: refactor via factory method
    const uct: UserChatTopic = new UserChatTopic();
    uct.issue = user.issue
    uct.subissue = user.subissue;
    localStorage.setItem("userChatTopic", JSON.stringify(uct));

    let exists: boolean;
    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/autotask/CreateFailedLoginTicket', body, { headers: headers });
    return rez;
  }

  GetChatPersonalization(clientId: number, clientType: string) {
    //let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let rez = this.http.get<any>(this.config.origin + '/api/account/GetChatPersonalizationForPartner?clientId=' + this.getClientIdParam() + '&clientType=' + this.getClientTypeParam());
    return rez;
  }

  RegisterToChat(user: User) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded','url-referrer': this.urlReferer });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('password', "123123");
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('issue', user.issue);
    urlSearchParams.append('subissue', user.subissue);
    urlSearchParams.append('message', user.message);
    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());

    //todo: refactor via factory method
    const uct: UserChatTopic = new UserChatTopic();
    uct.issue = user.issue
    uct.subissue = user.subissue;
    localStorage.setItem("userChatTopic", JSON.stringify(uct));

    let exists: boolean;
    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterToChat?clientId=' + this.getClientIdParam() + '&clientType=' + this.getClientTypeParam(), body, { headers: headers });
    return rez;
  }

  RegisterNewTelcoUserInAtDownTime(user: User) {
    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();

    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('clientType', this.getClientTypeParam());
    urlSearchParams.append('ParentClientId', this.getClientIdParam());
    urlSearchParams.append('Phone', user.phone);
    urlSearchParams.append('Adress', user.address);
    urlSearchParams.append('ClientId', this.getClientIdParam());

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());

    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterNewTelcoUserInAtDownTime', body, { headers: headers });

    return rez;
  }

  RegisterNewMspUserInAtDownTime(user: User) {
    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();

    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('clientType', this.getClientTypeParam());
    urlSearchParams.append('ParentClientId', this.getClientIdParam());
    urlSearchParams.append('Phone', user.phone);
    urlSearchParams.append('Adress', user.address);
    urlSearchParams.append('ClientId', this.getClientIdParam());

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());


    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterNewMspUserInAtDownTime', body, { headers: headers });

    return rez;
  }

  RegisterUserInAtAndV5(user: any) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('email', user.email);

    let body = urlSearchParams.toString();

    return this.http.post<any>(this.config.origin + '/api/account/registertome', body, { headers: headers });
  }

  RegisterNewMspUser(user: User) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();

    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('clientType', this.getClientTypeParam());
    urlSearchParams.append('ParentClientId', this.getClientIdParam());
    urlSearchParams.append('Phone', user.phone);
    urlSearchParams.append('Adress', user.address);
    urlSearchParams.append('ClientId', this.getClientIdParam());

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());

    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterNewMspUser', body, { headers: headers });

    return rez;
  }

  RegisterNewTelcoUser(user: User) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();

    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('clientType', this.getClientTypeParam());
    urlSearchParams.append('ParentClientId', this.getClientIdParam());
    urlSearchParams.append('Phone', user.phone);
    urlSearchParams.append('Adress', user.address);
    urlSearchParams.append('ClientId', this.getClientIdParam());

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());


    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterNewTelcoUser', body, { headers: headers });

    return rez;
  }

  RegisterNewUser(user: User) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();

    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('clientType', this.getClientTypeParam());
    urlSearchParams.append('ParentClientId', this.getClientIdParam());
    urlSearchParams.append('Phone', user.phone);
    urlSearchParams.append('Adress', user.address);

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());

    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RegisterNewUser', body, { headers: headers });

    return rez;
  }

  RedeemVoucherToUser(user: User) {

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();

    urlSearchParams.append('b1number', user.b1number);
    urlSearchParams.append('voucher', user.voucher);
    urlSearchParams.append('email', user.email);
    urlSearchParams.append('firstName', user.firstName);
    urlSearchParams.append('lastName', user.lastName);
    urlSearchParams.append('clientType', this.getClientTypeParam());
    urlSearchParams.append('ParentClientId', this.getClientIdParam());
    urlSearchParams.append('Phone', user.phone);
    urlSearchParams.append('Adress', user.address);

    if (this.getClientLanguageParam() == undefined || this.getClientLanguageParam() == 'undefined')
      urlSearchParams.append('language', 'en');
    else
      urlSearchParams.append('language', this.getClientLanguageParam());


    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/RedeemVoucherToUser', body, { headers: headers });

    return rez;
  }

  decryptUrlParam(encrypted: string, clientId: number) {
    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('b1number', encrypted);

    let body = urlSearchParams.toString();
    let rez = this.http.post<any>(this.config.origin + '/api/account/decryptUrl?clientId=' + clientId + '&encryptedUrl=' + encrypted, body, { headers: headers });
    return rez;
  }

  

  setAlreadyEnteredUserData(userData) {
    this.alreadyEntered.next(userData);
  }

  checkUser(userData) {
    this.registeredUserData.next(userData);
    this.setCurrentUser(userData);
  }

  setUserData(data: Subject<any>) {
    this.registeredUserData = data;
  }

  getUserData() {
    return this.registeredUserData;
  }

  public getClientIdParam(): string {
    let clientIdParam = localStorage.getItem('clientId');
    if (clientIdParam != undefined)
    {
      //console.log(clientIdParam);
      return clientIdParam;
    }   
    else
      return '';
  }

  public getFailedLogInAttempts(): number {
    let failedLogInAttempts = localStorage.getItem('failedLogInAttempts');
    if (failedLogInAttempts != undefined)
    {
      //console.log(clientIdParam);
      return +failedLogInAttempts;
    }   
    else
      return 0;
  }

  public getClientTypeParam(): string {
    let clientTypeParam = localStorage.getItem('clientType');
    if (clientTypeParam != undefined)
      return clientTypeParam;
    else
      return '';
  }

  public getClientLanguageParam(): string {
    let clientLanguageParam = localStorage.getItem('clientLanguageParam');
    if (clientLanguageParam != undefined)
      return clientLanguageParam;
    else
      return 'en';
  }

  public getClientWidgetParameter(): string {
    let isWidgetParameter = localStorage.getItem('isWidget');
    if (isWidgetParameter != undefined)
      return isWidgetParameter;
    else
      return 'false';
  }
  getQueryParamValue(dec, paramName) {
    const url = window.location.host + "/client?" + dec;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  invertColor(hex:any, bw:any) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        return '#FFFFFF';
    }
    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    let rs = (255 - r).toString(16);
    let gs = (255 - g).toString(16);
    let bs = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + this.padZero(rs,2) + this.padZero(gs,2) + this.padZero(bs,2);
  }

  padZero(str:any, len:any) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
  }

}

export const userServiceInjectables: Array<any> = [
  UsersService
];
