import { Injectable, EventEmitter, OnDestroy, NgZone } from "@angular/core";
import { Subject, ReplaySubject, Observer ,  Observable } from 'rxjs';
import { AppConfig } from "../AppConfig";
import { AuthenticationService } from './authentication.service';

export enum ConnectionState {
  Connecting = 0,
  Connected = 1,
  Reconnecting = 2,
  Disconnected = 4
}
declare var $: any;
@Injectable()
export class RhubService {
  private hubName: string = "ChatHub";
  private hubConnection: any;
  protected hubProxy: any;
  protected subscriptions = new Map<string, Subject<any>>();

  public constructor(private zone: NgZone,private config: AppConfig,private authService: AuthenticationService) {


  }

  public initRHub(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    let signalRServerEndPoint = this.config.origin;

    //for dev publish
    let token = localStorage.getItem('BearerToken');
    
    
    
    if(signalRServerEndPoint=='https://dev.globalmentoring.com/MentorAppWeb/')
    {
      this.hubConnection = $.hubConnection('/MentorAppWeb/signalr', {useDefaultPath: false});
    }
    else
    {
      this.hubConnection = $.hubConnection();
    }   

    this.hubConnection.qs = { "Authorization" : token };

    this.hubProxy = this.hubConnection.createHubProxy(this.hubName);

    this.hubConnection.stateChanged((state: any) => {
      const newState = this.getConnectionState(state);
      this.connectionStateChange.next(newState);
    });

    this.hubConnection.error((error: any) => {      
      
      if(error.context!=undefined &&error.context.status===401){
        this.authService.logout();
        window.location.reload();
      }else{
        this.connectionError.next(error);
      }
    });
  }

  public startRHub(): any {
    return this.hubConnection.start();
    
    
    // .done(function(){console.log("signalR is connected");})
    // .fail(function(error){
    //   console.log("signalR connection failure");});
  }


  public ngOnDestroy(): void {

    if (this.hubProxy == null) {
      return;
    }
    this.subscriptions.forEach((subject) => {
      subject.complete();
    });
    this.subscriptions.clear();
    this.hubProxy = null;

    this.connectionStateChange.complete();
    this.connectionError.complete();


  }

  private getConnectionState(state: any): ConnectionState {
    switch (state.newState) {
      case ConnectionState.Connecting:
        return ConnectionState.Connecting;
      case ConnectionState.Connected:
        return ConnectionState.Connected;
      case ConnectionState.Reconnecting:
        return ConnectionState.Reconnecting;
      case ConnectionState.Disconnected:
        return ConnectionState.Disconnected;
      default:
        return ConnectionState.Connecting;
    }
  }

  public connectionStateChange = new EventEmitter<ConnectionState>();

  public connectionError = new EventEmitter<Error>();

  public disconnect(): void {
    if (this.hubProxy == null) {
      return;
    }
    this.subscriptions.forEach((subject) => {
      subject.complete();
    });
    this.subscriptions.clear();
    this.hubProxy = null;
  }

  public async invoke(actionName: string, ...data: any[]): Promise<any> {
    await this.prepareHubProxy((proxy) => proxy.on("$$__FAKE_EVENT", () => { return; }));
    const result = await this.hubProxy.invoke(actionName, ...data);
    return result;
  }

  public async listen(eventName: string): Promise<Observable<any>> {
    const subject = await this.getCachedSubject(eventName);
    return this.getZonedObservable(subject);
  }

  private async getCachedSubject(eventName: string): Promise<Subject<any>> {
    if (this.subscriptions.has(eventName)) {
      return this.subscriptions.get(eventName);
    }
    const subject = new ReplaySubject<any>();
    await this.listenAsync(eventName, (...data: any[]) => {
      subject.next(data);
    });
    this.subscriptions.set(eventName, subject);
    return subject;
  }

  private getZonedObservable<T>(observable: Observable<T>): Observable<T> {
    return Observable.create((observer: Observer<T>) => {
      const onNext = (value: T) => this.zone.run(() => observer.next(value));
      const onError = (error: any) => this.zone.run(() => observer.error(error));
      const onComplete = () => this.zone.run(() => observer.complete());
      const subscription = observable.subscribe(onNext, onError, onComplete);
      return () => subscription.unsubscribe();
    });
  }

  public async listenAsync(eventName: string, handler: (...data: any[]) => void): Promise<any> {
    const result = await this.prepareHubProxy((proxy) => proxy.on(eventName, handler));
    return result;
  }

  private async prepareHubProxy(listener: (proxy: any) => void): Promise<any> {
    
    if (this.hubProxy == null) {
      this.hubProxy = this.hubConnection.createHubProxy(this.hubName);
    }
    listener(this.hubProxy);
    if (this.hubConnection.state === ConnectionState.Disconnected) 
    {
      const result = await this.hubConnection.start()
      return result;
    } else 
    {
        return null;
    }
  }
}
